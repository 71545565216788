<template>
  <p>welcome!</p>
  <p>欢迎来到znh之家</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'home',
  components: {},
})
</script>
