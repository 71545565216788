import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from './assets/tt.svg'


const _hoisted_1 = { class: "common-layout" }

import { onMounted, ref, watch } from 'vue'
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'

import { useRoute } from 'vue-router'
import { createLogger } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute()

const isCollapse = ref(false)
// const handleOpen = (key: string, keyPath: string[]) => {
//   console.log(key, keyPath)
// }
// const handleClose = (key: string, keyPath: string[]) => {
//   console.log(key, keyPath)
// }

let activeIndex = ref('1')
let actBred = ref('')
let actBredName = ref('')

// 面包屑
const handleSelect = (key: string, keyPath: string[]) => {

}

const pathList = ref()
 
const getCurrentPath = () => {
    console.log(route.matched);
    pathList.value = route.matched.filter(item => item.meta && item.meta.title);
}
 
onMounted(() => {
    getCurrentPath();
})
 
watch(route, (to, from) => {
    pathList.value = to.matched.filter(item => item.meta && item.meta.title);
    console.log(to);
    console.log(from);
}, { immediate: true });

const menuList = [
  {
    id: 1,
    path: '/',
    name: '首页',
  },
  {
    id: 2,
    path: '/userManage',
    name: '用户管理',
  },
]

return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_menu, {
              "default-active": _unref(activeIndex),
              class: "el-menu-demo",
              mode: "horizontal",
              ellipsis: false,
              onSelect: handleSelect
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, { index: "0" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      style: {"width":"100px"},
                      src: _imports_0,
                      alt: "Element logo"
                    }, null, -1),
                    _createElementVNode("p", { style: {"font-family":"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"} }, "TMiao", -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["default-active"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_aside, { width: "200px" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu, {
                  "default-active": "this.$route.path",
                  router: "",
                  class: "el-menu-vertical-demo"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuList, (item) => {
                      return _createVNode(_component_el_menu_item, {
                        key: item.index,
                        index: item.path,
                        onClick: handleSelect
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IconMenu))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["index"])
                    }), 64))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_container, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_main, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_breadcrumb, { separator: "/" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pathList.value, (item) => {
                          return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                            to: item.path,
                            key: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.meta.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_router_view)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})