<template>
  <div style="padding: 5px 20px;">
    <br>
    <el-form :inline="true"
             :model="listQuery"
             class="demo-form-inline">
      <el-form-item label="名称">
        <el-input v-model="listQuery.NAME"
                  placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item>

        <el-button type="primary"
                   @click="onSubmitSelect"
                   icon="el-icon-search">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success"
                   @click="TJCollectionForm"
                   icon="el-icon-edit">添加</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格-->
    <el-table :data="list"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
      <el-table-column align="center"
                       label="ID"
                       width="50"
                       type="index">
      </el-table-column>
      <el-table-column label="姓名"
                       prop="name">
      </el-table-column>
      <el-table-column label="年龄"
                       prop="age">
      </el-table-column>
      <el-table-column label="头像"
                       prop="user_photo"
                       width="100%"
                       height="100%">
        <template #default="scope">
          <el-image :src="scope.row.user_photo">
            <template #error>
              <div class="image-slot">
                <img src="../assets/user.jpg"
                     width="100%"
                     height="100%" />
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="性别"
                       prop="sex">
      </el-table-column>
      <el-table-column label="备注"
                       prop="remark">
      </el-table-column>
      <!-- <el-table-column label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>&nbsp;
          <span style="margin-left: 5px"
                v-text="(scope.row.create_time).substring(-1,19).replace('T',' ')"></span>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right"
                       label="操作"
                       width="170">
        <template v-slot="scope">
          <el-tooltip class="item"
                      effect="dark"
                      content="修改头像"
                      placement="top">
            <el-button type="primary"
                       circle
                       @click="alterPhoto(scope)">
              <el-icon>
                <Edit />
              </el-icon></el-button>
          </el-tooltip>
          <el-tooltip class="item"
                      effect="dark"
                      content="修改用户"
                      placement="top">
            <el-button type="primary"
                       circle
                       @click="BJCollectionForm(scope.$index)">
              <el-icon>
                <Setting />
              </el-icon></el-button>
          </el-tooltip>
          <el-tooltip class="item"
                      effect="dark"
                      content="删除"
                      placement="top">
            <el-button type="danger"
                       circle
                       @click="deleteVisible(scope)">
              <el-icon>
                <Delete />
              </el-icon>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="listQuery.page"
                   :page-sizes="[10]"
                   :page-size="listQuery.limit"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total"
                   :background="true">
    </el-pagination>

    <el-dialog :title="textMap[dialogStatus]"
               v-model="dialogFormVisible">
      <el-form ref="collectionform"
               :rules="rules"
               :model="ruleForm"
               label-width="120px"
               label-position="left">
        <el-form-item label="名称"
                      prop="name">
          <el-input placeholder="姓名"
                    v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="年龄"
                      prop="age">
          <el-input placeholder="年龄"
                    v-model="ruleForm.age"></el-input>
        </el-form-item>
        <el-form-item label="性别"
                      prop="value"
                      placeholder="请选择">
          <el-select v-model="ruleForm.sex"
                     style="width: 150px">
            <el-option label="男"
                       value="男"></el-option>
            <el-option label="女"
                       value="女"></el-option>
            <el-option label="保密"
                       value="保密"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="头像："
                      prop="user_photo">
          <el-avatar shape="square"
                     :size="100"
                     :fit="fit"
                     :src="ruleForm.user_photo" />
        </el-form-item>
        <el-form-item label="备注"
                      prop="remark">
          <el-input placeholder="remark"
                    v-model="ruleForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div name="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   v-if="dialogStatus=='create'"
                   @click="submitCollectionForm('collectionform')">添 加</el-button>
        <el-button type="warning"
                   v-else
                   @click="updateCollectionForm('collectionform')">修 改</el-button>
      </div>
    </el-dialog>

    <!-- 头像上传弹窗 -->
    <el-dialog title="修改头像"
               v-model="dialogVisible"
               width="30%">
      <!-- 1.将<el-upload>代码添加到<el-dialog>代码块中 -->
      <el-upload class="upload-demo"
                 drag
                 :limit="1"
                 :action="uploadFileURL"
                 :data="loadFileParams"
                 :before-upload="uploadBefore"
                 :file-list="attachList"
                 list-type="picture-card"
                 :on-success="handleAvatarSuccess"
                 :on-exceed="handleExceed">

        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script >

import { Delete, Edit, Plus, Setting, Share, Upload } from '@element-plus/icons-vue'
export default {
  components: {
    Edit,
    Delete,
    Plus,
    Setting
  },
  name: 'userManage',
  data () {
    return {
      attachmentId: [],
      uploadFileURL: '/api/upload',
      loadFileParams: {
        id: 0
      },
      dialogVisible: false,
      list: null,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        NAME: ''
      },
      dialogFormVisible: false,
      dialogStatus: 'create',
      textMap: {
        update: '修改用户',
        create: '添加用户'
      },

      ruleForm: {
        name: '',
        age: '',
        sex: '',
        user_photo: '',
        remark: ''
      },
      search: {
        name: '',
        age: '',
        sex: ''
      },

      rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        age: [{
          required: true,
          message: '请输入年龄',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请输入性别',
          trigger: 'blur'
        }]
      }
    }
  },
  created () {
    this.axiosdata()
  },
  methods: {

    handleExceed (files) {
      uploadRef.value.clearFiles()
      nextTick(() => {
        uploadRef.value.handleStart(files[0])
      })
    },
    uploadBefore (scope) { // 这个函数的目的就是在上传文件之前获取到文件的名字然后保存到携带的参数变量里面！

    },

    handleAvatarSuccess (res, file) {
      //上传图片后将 uploadDisabled 变量设置为 disabled
      if (res == "success") {
        this.uploadDisabled = 'disabled'
        this.axiosdata();
      }
    },
    alterPhoto (scope) {
      this.dialogVisible = true;
      this.loadFileParams.id = scope.row.id
    },
    handleSizeChange (val) {
      this.listQuery.limit = val;
      this.axiosdata();
    },
    handleCurrentChange (val) {
      this.listQuery.page = val;
      this.axiosdata();
    },
    onSubmitSelect () {
      this.search.name = this.listQuery.NAME;
      this.axios
        .get(`/api/queryUserByName?name=${this.search.name}`)
        .then((res) => {
          this.list = res.data
          console.log(res)
          this.total = res.data.length;
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    axiosdata () {
      this.listLoading = true;
      this.axios
        .get('/api/queryUserList')
        .then((res) => {
          this.list = res.data
          console.log(res)
          this.total = res.data.length;
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    /* 添加表单*/
    TJCollectionForm () {
      /* 表单重置*/
      Object.assign(this.$data.ruleForm, this.$options.data().ruleForm);

      this.dialogStatus = "create"
      this.dialogFormVisible = true
    },
    /* 编辑表单*/
    BJCollectionForm ($index) {
      //显示修改对话框
      this.ruleForm = JSON.parse(JSON.stringify(this.list[$index]))
      //以下效果和上面的不一样
      //this.ruleForm=this.list[$index]

      this.dialogStatus = "update"
      this.dialogFormVisible = true

    },

    /* 添加按钮*/
    submitCollectionForm (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.ruleForm.user_photo = 'http://106.15.198.68/source/user.jpg';
          this.axios.post('/api/addUser', this.ruleForm).then((response) => {
            if (response.data.message == '操作成功') {
              this.axiosdata();
              this.dialogFormVisible = false;
              this.$message.success('新增用户成功！');
            } else {
              this.$message.error('新增失败！');
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /* 修改按钮*/
    updateCollectionForm (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.axios.post('/api/updateUserById', this.ruleForm).then((response) => {
            if (response.data.message == "操作成功") {
              this.axiosdata();
              this.dialogFormVisible = false;
              this.$message.success('修改数据成功！');
            } else {
              this.$message.error('修改数据失败！');
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    /* 删除按钮*/
    deleteVisible (scope) {
      this.$confirm('确定要删除【' + scope.row.name + '】吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.get('/api/deleteUserById', { params: { id: scope.row.id } }).then((response) => {
          if (response.data.message == '操作成功') {
            this.axiosdata();
            this.dialogFormVisible = false;
            this.$message.success('删除完毕')
          } else {
            this.$message.error('删除数据失败！');
          }
        })
      }).catch(() => {
        this.$message.info('已取消')
      });

    },
  },

}
</script>
 
<style scoped>
.uploadIcon {
  border: 1px dashed var(--el-border-color);
  border-radius: 5px;
  padding: 2px 12px;
}
</style>