<template>
  <div class="common-layout">
    <el-container>
      <!-- 顶部 -->
      <el-header>
        <el-menu :default-active="activeIndex"
                 class="el-menu-demo"
                 mode="horizontal"
                 :ellipsis="false"
                 @select="handleSelect">
          <el-menu-item index="0">
            <img style="width: 100px"
                 src="./assets/tt.svg"
                 alt="Element logo" />
            <p style="font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">TMiao</p>
          </el-menu-item>
          <!-- <el-menu-item index="1">门户</el-menu-item> -->
        </el-menu>
      </el-header>
      <el-container>
        <!-- 侧边栏 -->
        <el-aside width="200px">
          <!-- 坍缩按钮 -->
          <!-- <el-switch v-model="isCollapse"
                     size="small"
                     active-text="隐藏"
                     inactive-text="展开" />
          <br /> -->
          <!-- 侧边菜单1 -->

          <el-menu default-active="this.$route.path"
                   router
                   class="el-menu-vertical-demo">
            <template v-for="(item) in menuList"
                      :key="item.index">
              <el-menu-item :index=item.path
                            @click="handleSelect">
                <el-icon>
                  <icon-menu />
                </el-icon>
                <template #title>{{item.name}}</template>
              </el-menu-item>
            </template>
          </el-menu>
        </el-aside>
        <!-- 主体 -->
        <el-container>
          <el-main>
            <!-- 面包屑 -->
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="item.path" v-for="item in pathList" :key="item.id">{{ item.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>  
                <router-view />
          </el-main>
          <!-- <el-footer>Footer</el-footer> -->
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'

import { useRoute } from 'vue-router'
import { createLogger } from 'vuex'

const route = useRoute()

const isCollapse = ref(false)
// const handleOpen = (key: string, keyPath: string[]) => {
//   console.log(key, keyPath)
// }
// const handleClose = (key: string, keyPath: string[]) => {
//   console.log(key, keyPath)
// }

let activeIndex = ref('1')
let actBred = ref('')
let actBredName = ref('')

// 面包屑
const handleSelect = (key: string, keyPath: string[]) => {

}

const pathList = ref()
 
const getCurrentPath = () => {
    console.log(route.matched);
    pathList.value = route.matched.filter(item => item.meta && item.meta.title);
}
 
onMounted(() => {
    getCurrentPath();
})
 
watch(route, (to, from) => {
    pathList.value = to.matched.filter(item => item.meta && item.meta.title);
    console.log(to);
    console.log(from);
}, { immediate: true });

const menuList = [
  {
    id: 1,
    path: '/',
    name: '首页',
  },
  {
    id: 2,
    path: '/userManage',
    name: '用户管理',
  },
]
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: auto;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
